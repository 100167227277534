<template>
  <div :class="computedClasses">
    <div class="single-input__container">
      <BaseText v-if="prefix" class="single-input__prefix">
        {{ prefix }}
      </BaseText>
      <div class="single-input__text-block">
        <input
          v-if="type === TEnabledTypes.PASSWORD"
          v-model="inputValue"
          data-testid="single-input-password"
          :value="value"
          class="text-p-4 single-input__input-password"
          v-bind="inputAttrs"
          :placeholder="placeholder"
          :type="inputType"
          :disabled="disabled"
          :aria-label="ariaLabel"
          tabindex="0"
        />
        <textarea
          v-else
          ref="single-input"
          v-model="inputValue"
          class="text-p-4"
          v-bind="inputAttrs"
          :value="value"
          :placeholder="placeholder"
          :type="type"
          :readonly="readonly"
          :disabled="disabled"
          :aria-label="ariaLabel"
          tabindex="0"
          :rows="calculateRows()"
          :style="computedFontSizeStyle"
          :maxlength="maxLength"
          v-on="computedInputListeners"
        />
        <span
          ref="single-input-mirror"
          class="single-input__mirror"
          :style="computedFontSizeStyle"
        >
          {{ inputValue }}
        </span>
      </div>

      <BaseIcon
        v-if="shoudRenderStatusIcon && type !== TEnabledTypes.PASSWORD"
        class="single-input__status-icon"
        :icon="statusIcon"
        width="24"
        aria-hidden="true"
      />
      <ButtonIconContextual
        v-else-if="shouldShowButton"
        class="single-input__close-icon"
        :icon="contextualProps.icon"
        size="small"
        :label="contextualProps.label"
        :disabled="disabled"
        @click="contextualProps.click"
      />
    </div>
    <HelperText
      v-if="helperText"
      :id="helperTextId"
      class="single-input__helper-text"
      :text="helperText"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';

import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import BaseText from '@/components/base-text/BaseText.vue';
import ButtonIconContextual from '@/components/button-icon/button-icon-contextual/ButtonIconContextual.vue';
import HelperText from '@/components/helper-text/HelperText.vue';
import { EStatus, TEnabledTypes, FontScale, FieldFilled } from './types';

@Component({
  name: 'SingleInput',
  components: {
    BaseIcon,
    BaseText,
    ButtonIconContextual,
    HelperText
  }
})
export default class SingleInput extends Vue {
  /**
   * Label para leitura por parte das tecnologias assistivas
   */
  @Prop({ type: String })
  readonly ariaLabel?: string;

  /**
   * Informa se o SingleInput estará desabilitado
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  /**
   * Adiciona um texto complementar abaixo do SingleInput
   */
  @Prop({ type: String })
  readonly helperText?: string;

  /**
   * O texto exibido dentro do input, quando não há valor digitado
   */
  @Prop({ type: String })
  readonly placeholder?: string;

  /**
   * Adiciona um conjunto de caracteres prefixados ao valor
   */
  @Prop({ type: String })
  readonly prefix?: string;

  /**
   * Informa se o SingleInput estará como readonly
   */
  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  /**
   * Informa se o botão para limpar o campo deve aparecer (lembre-se também de adicionar o evento @on-clear)
   */
  @Prop({ type: Boolean, default: false })
  readonly showClearButton!: boolean;

  /**
   * Adiciona validação visual para o SingleInput
   * @values default, success, error
   */
  @Prop({ type: String, default: EStatus.DEFAULT })
  readonly status!: string;

  /**
   * Adiciona um limite de caracteres para o input
   */
  @Prop({ type: Number })
  readonly maxLength?: number;

  /**
   * O tipo do SingleInput, seguindo as especificações HTML.
   *
   * @values email, number, password, tel, text, url
   */
  @Prop({
    type: String,
    default: 'text',
    validator: value =>
      Object.values(TEnabledTypes).includes(value as TEnabledTypes)
  })
  readonly type!: string;

  /**
   * Valor inicial do input
   */
  @Prop({ type: [String, Number] })
  readonly value?: string | number;

  /**
   * Evento disparado quando o usuário digita algo no campo
   */
  @Emit('input')
  public input(_value: string | number) {
    // Função que emite evento input
  }

  /**
   * Evento disparado quando o botão de limpar o input recebe um clique
   */
  @Emit('on-clear')
  public emitOnClear(_value: string | number) {
    // Função que emite evento on-clear
  }

  @Ref('single-input')
  private singleInputRef!: HTMLElement;

  @Ref('single-input-mirror')
  private singleInputMirrorRef!: HTMLElement;

  readonly TEnabledTypes = TEnabledTypes;

  private fontReducer = 1;

  public get computedInputListeners() {
    return {
      ...this.$listeners,
      input: (event: Event) => {
        const target = event.target as HTMLInputElement;
        this.input(target.value);
      },
      paste: (event: Event) => {
        const target = event.target as HTMLInputElement;
        this.input(target.value);
      }
    };
  }

  get inputValue() {
    return this.value;
  }

  set inputValue(value) {
    this.input(value || '');
  }

  public get computedClasses() {
    return [
      'single-input',
      `single-input--${this.status}`,
      {
        'single-input--readonly': this.readonly,
        'single-input--disabled': this.disabled
      }
    ];
  }

  public get shoudRenderStatusIcon() {
    return [EStatus.SUCCESS, EStatus.ERROR].includes(this.status as EStatus);
  }

  public get statusIcon() {
    return this.status === EStatus.SUCCESS ? 'EF0071' : 'EF0061';
  }

  public get helperTextId() {
    const randomId = Math.random().toString(16).slice(2);
    return `helper-text-${randomId}`;
  }

  public get inputAttrs() {
    return [
      this.$attrs,
      this.helperText && { 'aria-describedby': this.helperTextId }
    ];
  }

  /**
   * Valida se já o componente já renderizou o textarea e o valor não é mais indefinido.
   */
  public get hasFoundInput() {
    return this.inputValue && this.singleInputRef;
  }

  /**
   * Calcula o tamanho da fonte baseado em unidade "em" onde:
   * - 1em o tamanho da fonte definido no componente pai "40px"
   * - 0.575em o tamanho da fonte mínimo, equivalente a 0.575 de 1em(40px) = 23px;
   * Com isso o componente pega a informação de o quanto o "componente espelho já está preenchido".
   * A partir de 80% preenchido, a fonte é reduzida em 0.025em, e com menos de 80% a fonte é aumentada em 0.025em.
   * A fonte nunca pode ultrapassar o range de 0.575em ~ 1em.
   * Caso o tamanho do texto ultrapasse os 80% da primeira linha com o tamanho mínimo possível (0.575em=23px), são criadas novas linhas para este textarea.
   */
  public get resizeFont() {
    if (this.prefix) return this.fontReducer;
    this.setFontSizeToMax();
    this.recalcFontSize();
    this.setFontSizeToMin();
    return this.fontReducer;
  }

  /**
   * Aumenta ou reduz o fontReducer baseando-se no 'percentual preenchido' do campo
   */
  private recalcFontSize() {
    const isPercentualGreaterThanMax =
      this.computedPercentualTextFilled >= FieldFilled.MAX;
    const isPercentualLesserThanMin =
      this.computedPercentualTextFilled < FieldFilled.MIN;

    if (isPercentualGreaterThanMax) {
      this.fontReducer -= FontScale.FONT_PROGRESS;
      this.fontLesserThanMin();
    } else if (isPercentualLesserThanMin) {
      this.fontReducer += FontScale.FONT_PROGRESS;
      this.fontGreaterThanMax();
    }
  }

  /**
   * Caso seja identificado um overflow no textarea, a fonte deve ser automaticamente setada no mínimo
   */
  private setFontSizeToMin() {
    if (
      this.hasFoundInput &&
      this.singleInputRef.scrollHeight > FieldFilled.SCROLL_HEIGHT
    )
      this.fontReducer = FontScale.FONT_MIN;
  }

  /**
   * Enquanto o elemento não existir ou o valor ficar vazio, será setado o tamanho máximo (default)
   */
  private setFontSizeToMax() {
    const isNullOrUndefinedInput =
      !this.hasFoundInput || !this.computedPercentualTextFilled;

    if (isNullOrUndefinedInput) this.fontReducer = FontScale.FONT_MAX;
  }

  /**
   * Trava o fontReducer ao limite de tamanho mínimo
   */
  private fontLesserThanMin() {
    this.fontReducer = Math.max(this.fontReducer, FontScale.FONT_MIN);
  }

  /**
   * Trava o fontReducer ao limite de tamanho máximo
   */
  private fontGreaterThanMax() {
    this.fontReducer = Math.min(this.fontReducer, FontScale.FONT_MAX);
  }

  /**
   * Calcula o percentual de preenchimento de texto utilizando um span espelhado com o valor do textarea para validar o momendo de redução/aumento da fonte.
   */
  public get computedPercentualTextFilled() {
    const isFieldUndefined = !this.hasFoundInput || !this.inputValue;

    if (isFieldUndefined) return 0;
    return (
      (this.singleInputMirrorRef.clientWidth /
        this.singleInputRef.clientWidth) *
      100
    );
  }

  /**
   * Calcula o tamanho do scroll dividido pelo line-height para determinar em quantas linhas serão exibidas para o usuário.
   * O valor padrão do número de linhas é 1 caso o componente ainda não tenha sido renderizado ou o campo esteja vazio.
   */
  public calculateRows() {
    if (!this.hasFoundInput || !this.inputValue) return 1;
    return (
      ~~(this.singleInputMirrorRef.scrollHeight / FieldFilled.LINE_HEIGHT) || 1
    );
  }

  /**
   * Adiciona o estilo do tamanho da fonte computado em unidade "em"
   */
  public get computedFontSizeStyle() {
    return `font-size: ${this.resizeFont}em;`;
  }

  public get contextualProps() {
    const isPasswordField = this.type === TEnabledTypes.PASSWORD;
    return {
      click: isPasswordField ? this.toggleType : this.emitOnClear,
      label: isPasswordField ? 'Visualizar senha' : 'Limpar campo',
      icon: isPasswordField ? this.toggleIcon : 'EF0031'
    };
  }

  public get shouldShowButton() {
    return (
      this.type === TEnabledTypes.PASSWORD ||
      (this.inputValue && this.showClearButton)
    );
  }

  private isTextHidden = true;

  public get inputType() {
    return this.isTextHidden ? 'password' : 'text';
  }

  private get toggleIcon() {
    return this.isTextHidden ? 'EE0131' : 'EE0130';
  }

  private toggleType() {
    this.isTextHidden = !this.isTextHidden;
  }
}
</script>

<style lang="less" scoped>
@status-colors: {
  success: @element-on-success, @element-on-success;
  error: @element-on-error, @element-on-error;
};

@max-font-size: 40px;
@max-line-height: 56px;

.single-input {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: @size-border-x500 solid @divider-primary;
    padding: @size-spacing-x400 0 @size-spacing-x350;
    font-size: @max-font-size;
    line-height: @max-line-height;
    transition: border 0.2s ease-in-out;
  }

  &__text-block {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__mirror {
    overflow: auto;
    height: 0;
    align-self: flex-start;
    visibility: hidden;
  }

  &:not(&--disabled):not(&--readonly) {
    .single-input__container:hover,
    .single-input__container:focus-within {
      border-bottom: @size-border-x500 solid @element-primary;
    }
  }

  &__prefix,
  textarea {
    background: @background-secondary;
    color: @element-primary;
    font-size: inherit;
    line-height: inherit;
    transition: font-size 0.1s ease-in-out;
  }

  &__prefix {
    margin-right: @size-spacing-x350;
  }

  input {
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
    flex-grow: 1;
    resize: none;
    overflow-y: hidden;
    background: transparent;
    color: @element-primary;
  }

  &__input-password {
    font-size: 40px;
  }

  textarea {
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
    flex-grow: 1;
    resize: none;
    overflow-y: hidden;

    &::placeholder {
      color: @element-placeholder;
    }
  }

  &__helper-text {
    color: @element-secondary;
  }

  &--readonly,
  &--disabled {
    textarea,
    textarea::placeholder,
    .single-input__prefix,
    .single-input__helper-text {
      color: @element-disabled !important;
      background: transparent;
    }
  }

  &__close-icon {
    flex: none;

    &:focus {
      box-shadow: none;
    }
  }

  each(@status-colors, {
    &--@{key}:not(&--disabled):not(&--readonly) {
      .single-input__container {
        border-color: extract(@value, 1) !important;
      }

      .single-input__status-icon,
      .single-input__helper-text {
        color: extract(@value, 2);
      }
    }
  });
}
</style>
