<template>
  <component
    :is="as"
    :class="computedClassName"
    tabindex="0"
    :href="href"
    :aria-disabled="disabled"
    data-testid="button-link"
    v-on="!disabled && $listeners"
  >
    <!-- @slot Espaço padrão para receber o texto -->
    <slot />
  </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Link'
})
export default class Link extends Vue {
  /**
   * Permite atribuir um link para outra página, transformando o componente em um elemento <a>
   */
  @Prop({ type: String })
  readonly href?: string;

  /**
   * Desativa o link e não emite eventos
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  private get computedClassName() {
    return [
      'link',
      {
        disabled: this.disabled
      }
    ];
  }

  private get isHyperlink(): boolean {
    return !!this.href;
  }

  private get as(): string {
    return this.isHyperlink ? 'a' : 'button';
  }
}
</script>

<style lang="less" scoped>
@transition: 100ms cubic-bezier(0.55, 0, 0.1, 1);

.link {
  all: unset;
  background: transparent !important;
  border-radius: ~'min(0.3em, 8px)';
  box-sizing: border-box;
  color: @theme-primary;
  cursor: pointer;
  display: inline;
  font-weight: bold;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: opacity @transition;
  text-decoration: underline;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px @element-primary,
      0px 0px 1px 0 inset @element-primary;
    outline: none;
  }

  &.disabled {
    color: @text-disabled;
    cursor: not-allowed;
  }
}
</style>
